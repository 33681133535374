<template>
  <!-- ----------------------------------------------------------------------------- -->
  <!-- Vertical variation -->
  <!-- ----------------------------------------------------------------------------- -->
  <BaseCard
    title="Vertical variation"
    subtitle="By default <b-nav> appear on a horizontal line. Stack your navigation by setting the vertical prop."
    modalid="modal-13"
    modaltitle="Vertical variation"
  >
    <!-- --------------------------
    code view
    ---------------------------- -->
    <template v-slot:highlight>
      <pre class="mb-0">
        <code class="javascript">
&lt;b-nav vertical class=&quot;w-25&quot;&gt;
  &lt;b-nav-item active&gt;Active&lt;/b-nav-item&gt;
  &lt;b-nav-item&gt;Link&lt;/b-nav-item&gt;
  &lt;b-nav-item&gt;Another Link&lt;/b-nav-item&gt;
  &lt;b-nav-item disabled&gt;Disabled&lt;/b-nav-item&gt;
&lt;/b-nav&gt;
        </code>
      </pre>
    </template>
    <!-- --------------------------
    code view
    ---------------------------- -->

    <template v-slot:comcode>
      <b-nav vertical class="w-25">
        <b-nav-item active>Active</b-nav-item>
        <b-nav-item>Link</b-nav-item>
        <b-nav-item>Another Link</b-nav-item>
        <b-nav-item disabled>Disabled</b-nav-item>
      </b-nav>
    </template>
  </BaseCard>
</template>

<script>
import BaseCard from "../../card/BaseCard";

export default {
  name: "VerticalNav",

  data: () => ({}),
  components: { BaseCard },
};
</script>